<template>

    <div>
		<!-- Filters -->
		<blogs-list-filters
            :range-picker-filter.sync="rangePickerFilter"
            :states-filter.sync="statesFilter"
            :states-options="statesOptions"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                variant="primary"
                                :to="{ name: 'blogs-create' }">
                                <span class="text-nowrap">Agregar Blog</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refBlogListTable"
                class="position-relative"
                :items="fetchBlogs"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">
                
                <template #cell(title)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="data.item.image"			
								variant="light-success"
							/>
						</template>
						<b-link
							:to="{ name: 'blogs-edit', params: { id: data.item._id } }"
							class="font-weight-bold">
                            {{ data.item.title.length > 40 ? data.item.title.slice(0, 40).concat('...') : data.item.title }}
						</b-link>
					</b-media>
				</template>
                
                <!-- Column: Shor Description -->
                <template #cell(tags)="data">
                    <span> {{ data.item.tags.join(', ') }}</span>
                </template>

                <!-- Column: Shor Description -->
                <template #cell(shortDescription)="data">
                    <span> {{ data.item.shortDescription.slice(0, 50).concat('...') }}</span>
                </template>

                <!-- Column: State -->
                <template #cell(active)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.active ? 'success' : 'danger'}`"
                    >
                        {{ data.item.active ? 'PUBLICADO' : 'SIN PUBLICAR' }}
                    </b-badge>
                </template>

                <!-- Column: Expiry Date -->
                <template #cell(expiryDate)="data">
                    {{ data.item.expiryDate ? formatDate(data.item.expiryDate) : '---' }}
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        
                        <b-dropdown-item @click="openStatisticsModal(data.item)">
                            <feather-icon icon="BarChart2Icon" />
                            <span class="align-middle ml-50">Estadísticas</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'blogs-edit', params: { id: data.item._id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeStateBlog(data.item._id, true)" v-if="!data.item.active">
                            <feather-icon icon="CheckCircleIcon" />
                            <span class="align-middle ml-50">Publicar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeStateBlog(data.item._id, false)" v-else>
                            <feather-icon icon="SlashIcon" />
                            <span class="align-middle ml-50">No Publicar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteBlog(data.item._id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBlogs"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
        
        <b-modal
            v-model="modalShow"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-footer="true"
            size="lg"
            centered
            title="Estadísticas"
        >
            <b-card
                no-body
                class="card-statistics"
            >
            
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col
                            v-for="item in stats"
                            :key="item.icon"
                            xl="4"
                            sm="6"
                            class="mb-2 mb-md-0"
                        >
                            <b-media no-body>
                                <b-media-aside
                    
                                    class="mr-2"
                                >
                                    <b-avatar
                                        size="48"
                                        :variant="`light-${item.color}`"
                                    >
                                        <feather-icon
                                            size="24"
                                            :icon="item.icon"
                                        />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        {{ item.qty }}
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ item.subtitle }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card>
                <b-table 
                    responsive
                    :items="comments" 
                    :fields="commentsFields"
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                </b-table>
            </b-card>
        </b-modal>
        
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { onUnmounted, ref, reactive } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import BlogsListFilters from './BlogsListFilters.vue';
    import useBlogsList from './useBlogsList';
    import blogStoreModule from '../blogStoreModule';
    import moment from 'moment';
    import axios from 'axios';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            BlogsListFilters,

            vSelect
        },
        data () {
            return {
                statesOptions: [{
                    label: 'PUBLICADO',
                    value: true
                }, {
                    label: 'SIN PUBLICAR',
                    value: false
                }],
                
                commentsFields: [
                    { key: 'index', label: '#' },
                    { key: 'description', label: 'Comentario' },
                    { key: 'fullName', label: 'Usuario' },
                    { key: 'dateOfRegistration', label: 'Fecha de registro' }
                ]
            }
        },
        methods: {
            formatDate (date){
                return moment(date).format('DD/MM/YYYY');
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const BLOG_APP_STORE_MODULE_NAME = 'app-blog';

            // Register module
            if (!store.hasModule(BLOG_APP_STORE_MODULE_NAME)) store.registerModule(BLOG_APP_STORE_MODULE_NAME, blogStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(BLOG_APP_STORE_MODULE_NAME)) store.unregisterModule(BLOG_APP_STORE_MODULE_NAME);
            });

            const {
                fetchBlogs,
                tableColumns,
                perPage,
                currentPage,
                totalBlogs,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refBlogListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                statesFilter
            } = useBlogsList();

            const changeStateBlog = (id, state) => {
                store.dispatch('app-blog/changeStateBlog', { id, state })
                    .then( (response) => {
                        refetchData();
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });
                    })
                    .catch( () => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al cambiar el estado del blog',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    });
            };

            const deleteBlog = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar el blog?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-blog/deleteBlog', { id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el blog',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };
            
            const modalShow = ref(false)
            const stats = ref([])
            const comments = ref([])
            
            const resetStats = () => {
                stats.value = [];
                comments.value = [];
            };
            
            const numberFormat = (numberOfViews) => {
                if (typeof numberOfViews != 'number') return 0;
                
                if (numberOfViews >= 1000000) {
                    const formattedViews = `${(numberOfViews / 1000000).toFixed(1)}M`;
                    return formattedViews;
                } else if (numberOfViews >= 1000) {
                    const formattedViews = `${(numberOfViews / 1000).toFixed(1)}K`;
                    return formattedViews;
                } else {
                    return numberOfViews;
                }         
            }
            
            
            const openStatisticsModal = async (data) => {    
                resetStats()            
                
                const res = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/blog/${data._id}/comments`)   
                console.log(res.value)
                if(res && res.data) comments.value = res.data.comments
                                
                stats.value.push({
                    icon: 'HeartIcon',
                    color: 'primary',
                    qty: numberFormat(data.likes),
                    subtitle: 'Likes'
                }, {
                    icon: 'MessageSquareIcon',
                    color: 'info',
                    qty: numberFormat(data.comments),
                    subtitle: 'Comentarios'
                }, {
                    icon: 'EyeIcon',
                    color: 'warning',
                    qty: numberFormat(data.views),
                    subtitle: 'Vistas'
                })
                
                modalShow.value = true
            }

            return {
                fetchBlogs,
                tableColumns,
                perPage,
                currentPage,
                totalBlogs,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refBlogListTable,
                refetchData,
                
                //Statistics
                modalShow,
                stats,
                openStatisticsModal,
                comments,
               /*  commentsHead, */

                // Extra Filters
                rangePickerFilter,
                statesFilter,

                // Filter
                avatarText,
                deleteBlog,
                changeStateBlog
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>